import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import axios from 'axios';

import Popup from 'components/popup';

import { Wrapper } from './Form.styles';

const Form = (props) => {
  const { lang } = props;

  //POPSTATE
  const [response, setResponse] = useState({
    type: '',
    message: '',
    open: false,
  });

  const handleClick = () => {
    setResponse({ ...response, open: !response.open });
  };
  //POPSTATE

  //DEFS
  let isPt = lang === 'PT';
  //DEFS

  //FORMHANDLING
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const clearForm = () => {
    document.getElementById('form').reset();
  };

  const onSubmit = async (e) => {
    sendMessage();
  };

  const sendMessage = () => {
    setResponse({
      type: 'loading',
      message: '',
      open: true,
    });

    var contactsFormData = new FormData();

    contactsFormData.append('fname', document.querySelector('#fname').value);
    contactsFormData.append('email', document.querySelector('#femail').value);
    contactsFormData.append('phone', document.querySelector('#fphone').value);
    contactsFormData.append(
      'subject',
      document.querySelector('#fsubject').value
    );
    contactsFormData.append(
      'message',
      document.querySelector('#fmessage').value
    );

    console.log(document.querySelector('#fname').value);
    var config = {
      method: 'post',
      url: `https://admin.monarq.com.pt/wp-json/contact-form-7/v1/contact-forms/128/feedback`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: contactsFormData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 'mail_sent') {
          setResponse({
            type: 'success',
            open: true,
            message: isPt
              ? 'Obrigado pelo seu contacto. Vamos tentar responder assim que possível.'
              : "Thank you for reaching out, we'll try to answer as soon as possible.",
          });
          clearForm();
        } else {
          setResponse({
            type: 'error',
            open: true,
            message: isPt
              ? 'Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email.'
              : 'Something wrong happened. Please try again ou send us an email.',
          });
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setResponse({
          type: 'error',
          open: true,
          message: isPt
            ? 'Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email.'
            : 'Something wrong happened. Please try again ou send us an email.',
        });
      });
  };
  //FORMHANDLING

  return (
    <Wrapper>
      <form className="form" id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-input">
            <input
              className="input"
              placeholder={isPt ? 'O seu nome *' : 'Your name *'}
              id="fname"
              name="fname"
              type="text"
              {...register('fname')}
            />

            {errors.fname && (
              <p>{isPt ? 'Precisamos do seu nome.' : 'We need your name.'}</p>
            )}
          </div>
          <div className="form-input">
            <input
              className="input"
              placeholder={isPt ? 'O seu email *' : 'Your email *'}
              id="femail"
              name="femail"
              type="text"
            />

            {errors.femail && (
              <p>{isPt ? 'Precisamos do seu email.' : 'We need your email.'}</p>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-input">
            <input
              className="input"
              placeholder={isPt ? 'O seu telemóvel *' : 'Your phone *'}
              id="fphone"
              name="fphone"
              type="text"
            />

            {errors.fphone && (
              <p>
                {isPt
                  ? 'Precisamos do seu contacto telefónico.'
                  : 'We need your phone number.'}
              </p>
            )}
          </div>
          <div className="form-input">
            <input
              className="input"
              placeholder={isPt ? 'Assunto *' : 'Subject *'}
              id="fsubject"
              name="fsubject"
              type="text"
            />

            {errors.fsubject && (
              <p>
                {isPt
                  ? 'Precisamos do assunto para contacto.'
                  : 'We need the subsject for your approach.'}
              </p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-input form-input--full">
            <textarea
              className="input"
              rows={6}
              placeholder={isPt ? 'A sua mensagem *' : 'Your message *'}
              id="fmessage"
              name="fmessage"
              type="text"
            />

            {errors.fmessage && (
              <p>
                {isPt ? 'Precisamos de uma mensagem.' : 'We need a message.'}
              </p>
            )}
          </div>
        </div>
        <div className="submit-button">
          <button className="button">{isPt ? 'submeter' : 'submit'}</button>
        </div>
      </form>
      <Popup
        show={response.open}
        toggle={() => handleClick()}
        type={response.type}
        message={response.message}
      />
    </Wrapper>
  );
};

export default Form;

{
  /* <div>
<p style={{ padding: '10px' }}>
  {isPt
    ? 'Que amostras gostava de receber?'
    : 'Which samples would you like to receive?'}
</p>
<div className="select">
  <label className="select-label">
    <input type="checkbox" id="fbrand" />
    <span className="checkmark" />
    Branding
  </label>
  <label className="select-label">
    <input type="checkbox" id="fweb" />
    <span className="checkmark" />
    Webdesign
  </label>
  <label className="select-label">
    <input type="checkbox" id="fmd" />
    <span className="checkmark" />
    {isPt ? 'Marketing Digital' : 'Digital Marketing'}
  </label>
  <label className="select-label">
    <input type="checkbox" id="fdi" />
    <span className="checkmark" />
    {isPt ? 'Design Interiores' : 'Interior Design'}
  </label>
  <label className="select-label">
    <input type="checkbox" id="farq" />
    <span className="checkmark" />
    {isPt ? 'Arquitectura' : 'Architecture'}
  </label>
</div>
</div> */
}
