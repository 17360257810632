import React from 'react';

import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Form from 'components/form';
import TextBlock from 'components/textBlock';

const ContactsPage = (props) => {
  //PROPS
  const {
    lang,
    data: {
      page: {
        contacts: { image, text },
      },
    },
  } = props;
  //PROPS

  return (
    <div className="container">
      <div className="flex">
        <GatsbyImage
          image={image?.localFile?.childImageSharp?.gatsbyImageData}
          alt={image?.altText}
        />
        <TextBlock data={text} />
      </div>
      <div className="flex">
        <Form lang={lang} />
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d186.6365692970539!2d-8.404701922591183!3d41.54358911401081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24fea59c89bb9d%3A0x4f4640beaee9bf13!2sVorwerk!5e0!3m2!1spt-PT!2spt!4v1627725665943!5m2!1spt-PT!2spt"
            width="100% "
            height="100%"
            allowFullScreen=""
            loading="lazy"
            title="Google Map da fábrica da Adalmaris"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query Contactos($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      contacts {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
        }
      }
    }
  }
`;

export default ContactsPage;
